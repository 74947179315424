import { Tabs } from '../menu/menu.types';
import {
    PORTAL_SETTINGS_FETCHED,
    PortalSettingsAction,
    PortalSettingsState,
} from './portalSettings.types';
// eslint-disable-next-line import/no-cycle
import { ThunkActionPromise } from '../index';
import { FALLBACK_LANGUAGE } from '../../localization/localizationDefaultData';

export const initialState: PortalSettingsState = {
    customLinks: [],
    tabs: {},
    languages: {
        account: '',
        default: FALLBACK_LANGUAGE,
        supportedLanguages: [],
        contact: '',
    },
    // TODO: get impersonateMode from backend when it`s ready
    impersonateMode: false,
    hideCompleted: false,
};

export default function reducer(
    state = initialState,
    action: PortalSettingsAction,
): PortalSettingsState {
    switch (action.type) {
        case PORTAL_SETTINGS_FETCHED:
            return {
                ...state,
                customLinks: action.customLinks,
                tabs: action.tabs as Tabs,
                languages: action.languages,
                portalVersion: action.portalVersion,
                hideCompleted: action.hideCompleted,
                impersonateMode: action.impersonateMode,
            };
        default:
            return state;
    }
}

export const portalSettingsCreator = {
    fetchSettings:
        (): ThunkActionPromise =>
        (dispatch, getState, { api }) => {
            return api.getPortalSettings().then(settings => {
                // TODO Should be fixed on back-end
                const customLinks = settings.customLinks.filter(
                    link => link.url !== 'PORTAL_LINK_LOGOUT',
                );
                dispatch({
                    customLinks,
                    tabs: settings.tabs,
                    languages: settings.languages,
                    portalVersion: settings.portalVersion,
                    hideCompleted: settings.hideCompleted,
                    type: PORTAL_SETTINGS_FETCHED,
                    impersonateMode: settings.impersonateMode,
                });
            });
        },
};
