import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Grid } from 'worldapp-ui/shared';
import { MainTaskAction, SecondaryTaskAction } from '../../../redux/content/validateTaskActions';
import { TaskCardContentProps } from './CardsGrid.types';
import TaskCard from '../../ui/cards/TaskCard';
import { getSelectedTasksMap } from '../../../redux/tasksSelectedActions/tasksSelectedActionsSelector';
import { getTasksInActiveJobsMap } from '../../../redux/tasksActions/taskActionsSelector';
import { ActionItem } from '../TableView/TableActions';

interface TasksCardsRowProps {
    cards: TaskCardContentProps[];
    openTaskDetailView: (taskId: number) => void;
    dispatchMainAction: (action: MainTaskAction, taskId: number, taskDefinitionId: number) => void;
    dispatchSelectAction: (taskId: number, checked: boolean, shiftKey?: boolean) => void;
    dispatchDeselectTasks: () => void;
    dispatchExportAction: (taskId: number) => void;
    openActionPopup: (
        action: SecondaryTaskAction,
        taskId: number,
        taskDefinitionId: number,
    ) => void;
    cardWidth: number;
}

export const TasksCardsRow = memo(
    ({
        cards,
        openTaskDetailView,
        dispatchMainAction,
        openActionPopup,
        dispatchSelectAction,
        dispatchDeselectTasks,
        dispatchExportAction,
        cardWidth,
    }: TasksCardsRowProps): JSX.Element => {
        const { t } = useTranslation();
        const selectedTasksMap = useSelector(getSelectedTasksMap);
        const tasksInActiveJobsMap = useSelector(getTasksInActiveJobsMap);
        const items = [];

        const onActionClick = useCallback(
            (action: SecondaryTaskAction, taskId: number, taskDefinitionId: number) => () =>
                openActionPopup(action, taskId, taskDefinitionId),
            [openActionPopup],
        );

        for (let i = 0; i < cards.length; i++) {
            const card = cards[i];
            let onExpandButtonClick: (() => void) | null = () => openTaskDetailView(card.id);
            let menuActions: ActionItem[] = [];
            let onCardClick;
            let deselectTasks;

            if (card && card.secondaryActions) {
                menuActions = card.secondaryActions.map(action => ({
                    name: t(`TaskActions.${action}.Label`),
                    onClick:
                        action !== 'Export'
                            ? onActionClick(action, card.id, card.taskDefinitionId)
                            : () => dispatchExportAction(card.id),
                }));
            }

            if (card.mainAction) {
                onCardClick = () =>
                    dispatchMainAction(card.mainAction!, card.id, card.taskDefinitionId);
            }

            if (card.hasEditAction) {
                menuActions.unshift({
                    name: t(`TaskActions.EditForm.Label`),
                    onClick: () =>
                        dispatchMainAction('FillOutForm', card.id, card.taskDefinitionId),
                });
            }

            if (Object.keys(selectedTasksMap).length > 0) {
                onCardClick = (shiftKey?: boolean) =>
                    dispatchSelectAction(card.id, !selectedTasksMap[card.id], shiftKey);
                onExpandButtonClick = null;
            } else {
                menuActions.unshift({
                    name: t(`TaskActions.SelectTask.Label`),
                    onClick: () => dispatchSelectAction(card.id, true),
                });
            }
            if (Object.keys(selectedTasksMap).length > 0) {
                deselectTasks = () => dispatchDeselectTasks();
            }

            const cardProps = {
                menuActions,
                unread: card.unread,
                status: card.status,
                isStatusClosed: card.isStatusClosed,
                dueDate: card.dueDate,
                title: card.title,
                searchQuery: card.searchQuery,
                assignee: card.assignee,
                taskDefinition: card.taskDefinition,
                isActive: card.isActive,
                shouldHighlight: card.shouldHighlight,
                isTaskDetailViewOpened: card.isTaskDetailViewOpened,
                selected: selectedTasksMap[card.id],
                inProgress: tasksInActiveJobsMap[card.id],
                cardWidth,
                ...(onExpandButtonClick ? { onExpandButtonClick } : null),
                ...(onCardClick ? { onCardClick } : null),
                ...(deselectTasks ? { deselectTasks } : null),
            };

            items.push(
                <Grid item={true} key={card.id}>
                    <TaskCard {...cardProps} data-testid={`card-task_id-${card.id}`} />
                </Grid>,
            );
        }
        return <>{items}</>;
    },
);
