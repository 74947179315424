import { NumericDictionary, WithOptional } from 'worldapp-fe-utils';
import { ApiGW } from '../../types/DTO/api-gw';

export const FETCH_TDS = 'Tasks/FETCH_TDS';
export const FETCH_META = 'Tasks/FETCH_META';
export const GET_CREATE_TASK_PERMIT = 'Tasks/GET_CREATE_TASK_PERMIT';
export const TASK_PERMITS_LOADING = 'Tasks/TASK_PERMITS_LOADING';

export type TaskStatusesById = NumericDictionary<TaskStatus>;

export type TaskDefinition = WithOptional<ApiGW.TaskDefinitionDTO, 'form' | 'statusQuestionId'> & {
    count?: number;
    managed?: boolean;
    statusesById?: TaskStatusesById;
};

export type TaskStatus = ApiGW.TaskStatusDTO;

export interface CreateTaskParams {
    assigneeId: number;
    description?: string;
    summary: string;
}

interface DataModelRowDTO {
    fields: [
        {
            columnId: number;
            value: string;
            type: string;
        },
    ];
    id: number;
    modified: number;
}
export interface DataModelRowsDTO {
    items: DataModelRowDTO[];
    limit: number;
    offset: number;
}

export interface FetchTDSAction {
    type: typeof FETCH_TDS;
    taskDefinitions: TaskDefinition[];
    totalTDs: number;
}

interface FetchMetaAction {
    type: typeof FETCH_META;
    taskDefinitions: TaskDefinition[];
    totalTasks: number;
}
interface GetCreateTaskPermitAction {
    type: typeof GET_CREATE_TASK_PERMIT;
    tdIdsWithCreateTask: number[];
}

interface TaskPermitsLoadingAction {
    type: typeof TASK_PERMITS_LOADING;
    isLoading: boolean;
}

export type TasksAction =
    | FetchTDSAction
    | FetchMetaAction
    | GetCreateTaskPermitAction
    | TaskPermitsLoadingAction;

export type TaskDefinitionsById = NumericDictionary<TaskDefinition>;

export interface TasksDefinitionsState {
    taskDefinitions: TaskDefinition[];
    taskDefinitionsById: TaskDefinitionsById;
    totalTDs: number;
    totalTasks: number;
    tdIdsWithCreateTask: number[];
    taskPermitsLoading: boolean;
}
