import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, ToggleButton, ToggleButtonGroup } from 'worldapp-ui';
import { useDispatch, useSelector } from 'react-redux';
import { useCalendarToggleViewStyles } from './TaskCalendarHeader.styles';
import { getCalendarView } from '../../../redux/calendar/calendarSelector';
import { calendarCreator } from '../../../redux/calendar/calendar';
import { CalendarView } from '../../../redux/calendar/calendar.types';

const TaskCalendarHeaderToggleView = (): JSX.Element => {
    const { t } = useTranslation();
    const { root, label, selected, headerToggleView } = useCalendarToggleViewStyles();
    const calendarView = useSelector(getCalendarView);

    const dispatch = useDispatch();

    const changeCalendarView = (event: any, view: CalendarView) => {
        dispatch(calendarCreator.changeCalendarView(view));
    };

    return (
        <ToggleButtonGroup
            className={headerToggleView}
            value={calendarView}
            onChange={changeCalendarView}
            exclusive
            data-testid="task-calendar-header_toggle-view"
        >
            <ToggleButton
                value="day"
                aria-label="day"
                classes={{ root, label, selected }}
                data-testid="task-calendar-header_toggle-day"
            >
                <Text variant="r14r">{t('TasksCalendar.Day')}</Text>
            </ToggleButton>
            <ToggleButton
                value="week"
                aria-label="week"
                classes={{ root, label, selected }}
                data-testid="task-calendar-header_toggle-week"
            >
                <Text variant="r14r">{t('TasksCalendar.Week')}</Text>
            </ToggleButton>
            <ToggleButton
                value="month"
                aria-label="month"
                classes={{ root, label, selected }}
                data-testid="task-calendar-header_toggle-month"
            >
                <Text variant="r14r">{t('TasksCalendar.Month')}</Text>
            </ToggleButton>
        </ToggleButtonGroup>
    );
};
export default TaskCalendarHeaderToggleView;
