import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DefaultPopup, RoundedAccentButton, RoundedAccentCollapsedButton } from 'worldapp-ui';
import classNames from 'classnames';
import { PlusHeavy } from 'worldapp-ui/shared/icons';
import { defaultPopupButtonsDirectionOptions } from 'worldapp-ui/shared/popup/components/DefaultPopupFooter';
import { useActionCreators } from '../../../hooks/useActionCreators';
import { getStartFormSelectedItem } from '../../../redux/startForm/startFormSelector';
import { getTaskPermitsLoading } from '../../../redux/taskDefinitions/taskDefinitionsSelector';
import StartFormPopup from '../StartFormPopup/StartFormPopup';
import { startFormButtonStyles } from './StartFormButton.styles';
import { getIncludedTasksSelected } from '../../../redux/tasksSelectedActions/tasksSelectedActionsSelector';
import { isMobile } from '../../../utils/browser.utils';

interface StartFormProps {
    isCollapsed: boolean;
}

const StartForm: React.FC<StartFormProps> = props => {
    const { isCollapsed } = props;
    const classes = startFormButtonStyles();
    const isLoading = useSelector(getTaskPermitsLoading);
    const selectedValue = useSelector(getStartFormSelectedItem);
    const { taskDefinitionsCreator, votingCreator, startFormCreator } = useActionCreators();
    const [isPopupOpen, togglePopup] = React.useState(false);
    const selectedTasks = useSelector(getIncludedTasksSelected);
    const [startFormClicked, setStartFormClicked] = useState(false);

    const { t } = useTranslation();

    const openStartFormDialog = async () => {
        await taskDefinitionsCreator.getCreateTaskPermits();
        startFormCreator.selectStartFormItem();
        togglePopup(true);
    };

    const onClosePopup = () => {
        togglePopup(false);
        startFormCreator.clearStartForm();
    };

    const onConfirmButton = () => {
        setStartFormClicked(true);
        votingCreator.startForm();
    };

    const popupFooter = {
        confirmButton: {
            label: t('StartForm.StartForm'),
            clickHandler: onConfirmButton,
            buttonProps: {
                disabled: !selectedValue,
                inProgress: startFormClicked,
            },
        },
        cancelButton: {
            label: t('Common.CANCEL'),
            clickHandler: onClosePopup,
        },
        mobileButtonsDirection: defaultPopupButtonsDirectionOptions.ROW,
    };

    return (
        <>
            {!(selectedTasks.length >= 1 && isMobile) && (
                <>
                    <div
                        className={classNames(
                            classes.container,
                            isCollapsed && classes.containerCollapsed,
                        )}
                    >
                        {isCollapsed ? (
                            <RoundedAccentCollapsedButton
                                icon={<PlusHeavy />}
                                inProgress={isLoading}
                                data-testid="btn_start-form"
                                onClick={openStartFormDialog}
                                aria-label={t('StartForm.StartForm')}
                            />
                        ) : (
                            <RoundedAccentButton
                                fullWidth={!isCollapsed}
                                icon={<PlusHeavy />}
                                iconLabel={t('StartForm.StartForm')}
                                inProgress={isLoading}
                                data-testid="btn_start-form"
                                onClick={openStartFormDialog}
                            />
                        )}
                    </div>

                    <DefaultPopup
                        id="start-form"
                        open={isPopupOpen}
                        header={{ title: t('StartForm.StartForm') }}
                        footer={popupFooter}
                        contentDesktopWidth={504}
                        fixedHeight
                    >
                        <StartFormPopup />
                    </DefaultPopup>
                </>
            )}
        </>
    );
};

export default StartForm;
