export const FEATURES_CHECKED = 'Features/FEATURES_CHECKED';

export const features = ['online_in_iOS_opx202', 'optimize_forms_title_load_opx540'];

export type Feature = typeof features[number];

export type FeaturesPayload = {
    [key in Feature]: boolean;
};

interface FeatureFetchedAction {
    type: typeof FEATURES_CHECKED;
    features: FeaturesPayload;
}

export type FeaturesAction = FeatureFetchedAction;

export type FeaturesState = FeaturesPayload | null;
