import produce from 'immer';
import {
    APPLY_FILTERS,
    CHANGE_DATE_FILTER_RANGE,
    CHANGE_NUMBER_FILTER_RANGE,
    CHANGE_POPUP_STAGE,
    CLEAR_FILTER_VALUES,
    ConfigurableFilterType,
    DELETE_SAVED_FILTERS_SET,
    DUE_DATE_FILTER_ID,
    FilterMeta,
    FiltersPopupStage,
    GET_FILTERS_META,
    RESET_FILTERS_SET_VALUES,
    SAVE_FILTERS_SET,
    SET_GENERAL_AS_ACTIVE,
    SET_SAVED_AS_ACTIVE,
    TasksConfigurableFiltersAction,
    TasksConfigurableFiltersState,
    TOGGLE_PREDEFINED_FILTER,
    TOGGLE_STRING_ITEM_FILTER_VALUE,
    VALIDATE_APPLIED_FILTERS,
    GET_TASK_PROPERTIES_VALUES,
    GET_ASSIGNEE_FILTER_META,
    TOGGLE_APPLIED_STRING_FILTER_VALUE,
} from './tasksConfigurableFilters.types';
import { DateFilterOption, DateFilterRange } from '../../components/ConfigurableFilters/DateFilter';
import {
    ChangeQuickFiltersAction,
    TaskQuickFilters,
    ONLY_RECENT_QUICK_FILTER,
    QuickFiltersAction,
    COPY_QUICK_FILTERS_TO_APPLIED,
} from '../contentQuickFilters/contentQiuckFilters.types';
import { CONTENT_CATEGORY_TASKS } from '../content/category.types';
import {
    HYDRATE_STATE_FROM_STORAGE,
    HydrateStateFromStorageAction,
} from '../initialLoader/initialLoader.types';
import { TasksConfigurableFiltersCreator } from './tasksConfigurableFiltersCreator.types';
import { NumberFilterRange } from '../../components/ConfigurableFilters/NumberFilter';
import { DEFAULT_CUSTOM_PROPERTIES_LIMIT } from './tasksConfigurableFiltersSelector';
import { isRequireDueDate } from '../taskDefinitions/taskDefinitionsSelector.reselect';

export const initialState: TasksConfigurableFiltersState = {
    popupStage: FiltersPopupStage.Closed,
    filtersMeta: null,
    generalFiltersSet: null,
    activeFiltersSet: null,
    activeFiltersSetName: null,
    appliedFiltersSets: {},
    savedFiltersSets: {},
};

export const reducer = (
    state = initialState,
    action:
        | TasksConfigurableFiltersAction
        | ChangeQuickFiltersAction
        | HydrateStateFromStorageAction
        | QuickFiltersAction,
): TasksConfigurableFiltersState =>
    produce(state, (draft: TasksConfigurableFiltersState) => {
        switch (action.type) {
            case HYDRATE_STATE_FROM_STORAGE: {
                if (!action.state.tasksConfigurableFilters) return;
                const { savedFiltersSets, appliedFiltersSets } = action.state
                    .tasksConfigurableFilters as TasksConfigurableFiltersState;
                if (savedFiltersSets) {
                    draft.savedFiltersSets = savedFiltersSets;
                }
                if (appliedFiltersSets) {
                    draft.appliedFiltersSets = appliedFiltersSets;
                }
                return;
            }
            case APPLY_FILTERS: {
                draft.appliedFiltersSets[action.taskDefinitionId] = action.filtersSet;
                return;
            }
            case CHANGE_DATE_FILTER_RANGE: {
                if (!draft.activeFiltersSet) {
                    return;
                }
                if (action.range.option === DateFilterOption.None) {
                    delete draft.activeFiltersSet.custom[action.filterId];
                } else {
                    draft.activeFiltersSet.custom[action.filterId] = {
                        type: ConfigurableFilterType.Date,
                        id: action.filterId,
                        range: action.range,
                    };
                }
                return;
            }
            case CHANGE_NUMBER_FILTER_RANGE: {
                if (!draft.activeFiltersSet) return;
                if (action.range.from === null && action.range.to === null) {
                    delete draft.activeFiltersSet.custom[action.filterId];
                } else {
                    draft.activeFiltersSet.custom[action.filterId] = {
                        type: ConfigurableFilterType.Number,
                        id: action.filterId,
                        range: action.range,
                    };
                }
                return;
            }
            case CHANGE_POPUP_STAGE: {
                switch (action.stage) {
                    case FiltersPopupStage.GeneralFiltersTab: {
                        if (draft.popupStage === FiltersPopupStage.Closed) {
                            const applied = draft.appliedFiltersSets[action.taskDefinitionId];
                            draft.generalFiltersSet = applied ?? action.defaultFiltersSet;
                        }
                        draft.activeFiltersSet = draft.generalFiltersSet;
                        break;
                    }
                    case FiltersPopupStage.FiltersSetsTab: {
                        if (draft.popupStage === FiltersPopupStage.GeneralFiltersTab) {
                            draft.generalFiltersSet = draft.activeFiltersSet;
                        }
                        break;
                    }
                    case FiltersPopupStage.Closed: {
                        draft.activeFiltersSetName = null;
                        draft.generalFiltersSet = null;
                        draft.activeFiltersSet = null;
                        draft.filtersMeta = null;
                        break;
                    }
                }
                draft.popupStage = action.stage;
                return;
            }
            case COPY_QUICK_FILTERS_TO_APPLIED: {
                if (action.category === CONTENT_CATEGORY_TASKS) {
                    if (!draft.appliedFiltersSets[action.itemId]) {
                        draft.appliedFiltersSets[action.itemId] = {
                            predefined: { ...action.filters },
                            custom: {},
                        };
                    } else {
                        draft.appliedFiltersSets[action.itemId].predefined = { ...action.filters };
                    }
                }
                return;
            }
            case CLEAR_FILTER_VALUES: {
                if (!draft.activeFiltersSet) return;
                const filter = draft.activeFiltersSet.custom[action.filterId];
                if (filter) {
                    delete draft.activeFiltersSet.custom[action.filterId];
                }
                return;
            }
            case DELETE_SAVED_FILTERS_SET: {
                const savedTDFilters = draft.savedFiltersSets[action.taskDefinitionId];
                if (savedTDFilters) {
                    delete savedTDFilters[action.filtersSetName];
                    if (draft.activeFiltersSetName === action.filtersSetName) {
                        draft.activeFiltersSetName = null;
                    }
                }
                return;
            }
            case GET_FILTERS_META: {
                draft.filtersMeta = action.filtersMeta;
                return;
            }
            case RESET_FILTERS_SET_VALUES: {
                if (!draft.activeFiltersSet) return;
                draft.activeFiltersSet = action.filtersSet;
                return;
            }
            case SAVE_FILTERS_SET: {
                const savedTDFilters = draft.savedFiltersSets[action.taskDefinitionId] || {};
                savedTDFilters[action.filtersSetName] = action.filtersSet;
                draft.savedFiltersSets[action.taskDefinitionId] = savedTDFilters;
                return;
            }
            case SET_GENERAL_AS_ACTIVE: {
                draft.activeFiltersSet = draft.generalFiltersSet;
                draft.activeFiltersSetName = null;
                return;
            }
            case SET_SAVED_AS_ACTIVE: {
                const taskDefinitionFiltersSets = draft.savedFiltersSets[action.taskDefinitionId];
                if (!taskDefinitionFiltersSets) return;
                const filtersSet = taskDefinitionFiltersSets[action.filtersSetName];
                if (filtersSet) {
                    draft.activeFiltersSet = filtersSet;
                    draft.activeFiltersSetName = action.filtersSetName;
                }
                return;
            }
            case TOGGLE_PREDEFINED_FILTER: {
                if (!draft.activeFiltersSet) return;
                draft.activeFiltersSet.predefined[action.filter] =
                    !draft.activeFiltersSet.predefined[action.filter];
                return;
            }
            case TOGGLE_STRING_ITEM_FILTER_VALUE: {
                if (!draft.activeFiltersSet) return;
                if (!draft.activeFiltersSet.custom[action.filterId]) {
                    draft.activeFiltersSet.custom[action.filterId] = {
                        type: ConfigurableFilterType.String,
                        id: action.filterId,
                        items: [],
                    };
                }
                const filter = draft.activeFiltersSet.custom[action.filterId];
                if (filter.type !== ConfigurableFilterType.String) return;

                const itemIndex = filter.items.findIndex(item => item === action.value);
                if (itemIndex !== -1) {
                    filter.items.splice(itemIndex, 1);
                } else {
                    filter.items.push(action.value);
                }
                if (filter.items.length === 0) {
                    delete draft.activeFiltersSet.custom[action.filterId];
                }
                return;
            }
            case VALIDATE_APPLIED_FILTERS: {
                const appliedFiltersSet = draft.appliedFiltersSets[action.taskDefinitionId];
                if (
                    !action.requireDueDate &&
                    appliedFiltersSet &&
                    appliedFiltersSet.custom[DUE_DATE_FILTER_ID]
                ) {
                    delete appliedFiltersSet.custom[DUE_DATE_FILTER_ID];
                }
                return;
            }
            case GET_TASK_PROPERTIES_VALUES: {
                const filter = draft.filtersMeta?.find(
                    filterMeta => filterMeta.id === action.taskPropertyId,
                );
                if (!filter || filter.type !== ConfigurableFilterType.String) return;

                const newItems = action.taskPropertiesValues.values;

                filter.items = action.resetItems ? newItems : [...filter.items, ...newItems];
                filter.hasMoreItems = newItems.length === DEFAULT_CUSTOM_PROPERTIES_LIMIT;
                filter.offset = action.resetItems
                    ? DEFAULT_CUSTOM_PROPERTIES_LIMIT
                    : filter.offset + DEFAULT_CUSTOM_PROPERTIES_LIMIT;
                filter.query = action.query || '';
                return;
            }
            case GET_ASSIGNEE_FILTER_META: {
                const { filtersMeta } = draft;
                if (!filtersMeta) return;
                filtersMeta[0] = action.filterMeta;
                return;
            }
            case ONLY_RECENT_QUICK_FILTER: {
                if (action.category === CONTENT_CATEGORY_TASKS) {
                    delete draft.appliedFiltersSets[action.itemId];
                }
                return;
            }
            case TOGGLE_APPLIED_STRING_FILTER_VALUE: {
                if (!draft.appliedFiltersSets[action.taskDefinitionId]) {
                    draft.appliedFiltersSets[action.taskDefinitionId] = action.defaultFiltersSet!;
                }
                const appliedFiltersSets = draft.appliedFiltersSets[action.taskDefinitionId];

                if (!appliedFiltersSets.custom[action.filterId]) {
                    appliedFiltersSets.custom[action.filterId] = {
                        type: ConfigurableFilterType.String,
                        id: action.filterId,
                        items: [],
                    };
                }
                const filter = appliedFiltersSets.custom[action.filterId];
                if (filter.type !== ConfigurableFilterType.String) return;

                const itemIndex = filter.items.findIndex(item => item === action.itemName);
                if (itemIndex !== -1) {
                    filter.items.splice(itemIndex, 1);
                } else {
                    filter.items.push(action.itemName);
                }
                if (filter.items.length === 0) {
                    delete appliedFiltersSets.custom[action.filterId];
                }
                return;
            }
            default:
                return draft;
        }
    });

export const tasksConfigurableFiltersCreator: TasksConfigurableFiltersCreator = {
    applyFilters:
        () =>
        (
            dispatch,
            getState,
            {
                contentCreator: content,
                tasksConfigurableFiltersSelector: filtersSelector,
                contentSelector,
            },
        ) => {
            const state = getState();
            const taskDefinitionId = contentSelector.getContentItemId(state) as number;
            const filtersSet = filtersSelector.getActiveFiltersSet(state);

            if (filtersSet) {
                dispatch({ type: APPLY_FILTERS, taskDefinitionId, filtersSet });
            }

            dispatch({
                type: CHANGE_POPUP_STAGE,
                taskDefinitionId,
                stage: FiltersPopupStage.Closed,
            });

            return dispatch(content.refresh(true));
        },

    changeDateFilterRange: (filterId: number | string, range: DateFilterRange) => dispatch => {
        return dispatch({
            type: CHANGE_DATE_FILTER_RANGE,
            filterId,
            range,
        });
    },

    changeNumberFilterRange: (filterId: number | string, range: NumberFilterRange) => dispatch => {
        return dispatch({
            type: CHANGE_NUMBER_FILTER_RANGE,
            filterId,
            range,
        });
    },

    changePopupStage:
        (stage: FiltersPopupStage) =>
        (dispatch, getState, { contentSelector, tasksConfigurableFiltersSelector }) => {
            const state = getState();
            const taskDefinitionId = contentSelector.getContentItemId(state);
            if (!contentSelector.isContentCategoryTasks(state) || !taskDefinitionId) return;
            const defaultFiltersSet = tasksConfigurableFiltersSelector.getDefaultFiltersSet(state);
            return dispatch({
                type: CHANGE_POPUP_STAGE,
                stage,
                taskDefinitionId,
                defaultFiltersSet,
            });
        },

    clearFilterValues: (filterId: number | string) => dispatch => {
        return dispatch({
            type: CLEAR_FILTER_VALUES,
            filterId,
        });
    },

    deleteSavedFiltersSet:
        (filtersSetName: string) =>
        (dispatch, getState, { contentSelector }) => {
            const state = getState();
            const taskDefinitionId = contentSelector.getContentItemId(state);
            if (!contentSelector.isContentCategoryTasks(state) || !taskDefinitionId) return;

            return dispatch({ type: DELETE_SAVED_FILTERS_SET, taskDefinitionId, filtersSetName });
        },

    editSavedFiltersSet:
        (filtersSetName: string) =>
        (dispatch, getState, { tasksConfigurableFiltersCreator: creator, contentSelector }) => {
            const state = getState();
            const taskDefinitionId = contentSelector.getContentItemId(state);
            if (!contentSelector.isContentCategoryTasks(state) || !taskDefinitionId) return;

            dispatch({ type: SET_SAVED_AS_ACTIVE, taskDefinitionId, filtersSetName });

            return dispatch(creator.changePopupStage(FiltersPopupStage.EditSavedFilter));
        },

    getFiltersMeta:
        () =>
        async (
            dispatch,
            getState,
            { api, contentSelector, tasksConfigurableFiltersSelector: filterSelector },
        ) => {
            const filtersMeta: FilterMeta[] = [];
            const state = getState();
            if (!contentSelector.isContentCategoryTasks(state)) return;

            const assigneeMeta = filterSelector.getAssigneeFilterMeta(state);
            if (assigneeMeta) filtersMeta.push(assigneeMeta);

            const statusMeta = filterSelector.getStatusFilterMeta(state);
            if (statusMeta) filtersMeta.push(statusMeta);

            const dueDateMeta = filterSelector.getDueDateFilterMeta(state);
            if (dueDateMeta) filtersMeta.push(dueDateMeta);

            const assignDateMeta = filterSelector.getAssignDateFilterMeta(state);
            if (assignDateMeta) filtersMeta.push(assignDateMeta);

            const tdsMeta = filterSelector.getTDFilterMeta(state);
            if (tdsMeta) filtersMeta.push(tdsMeta);

            const customPropsMeta = await filterSelector.getCustomPropertyFiltersMeta(
                state,
                api.getTaskPropertyValues,
            );

            return dispatch({
                type: GET_FILTERS_META,
                filtersMeta: [...filtersMeta, ...customPropsMeta],
            });
        },

    openFiltersPopup:
        () =>
        async (
            dispatch,
            getState,
            {
                tasksConfigurableFiltersCreator: creator,
                contentSelector,
                taskDefinitionAssigneesCreator,
            },
        ) => {
            const activeItemId = contentSelector.getContentItemId(getState());
            if (!activeItemId) return;

            dispatch(creator.changePopupStage(FiltersPopupStage.GeneralFiltersTab));
            await dispatch(taskDefinitionAssigneesCreator.fetchAssigneeValues(true));

            await dispatch(creator.getFiltersMeta());
        },

    resetActiveFiltersSet:
        () =>
        (dispatch, getState, { tasksConfigurableFiltersSelector }) => {
            return dispatch({
                type: RESET_FILTERS_SET_VALUES,
                filtersSet: tasksConfigurableFiltersSelector.getEmptyFiltersSet(getState()),
            });
        },

    saveFiltersSet:
        (filtersSetName: string) =>
        (
            dispatch,
            getState,
            { contentSelector, tasksConfigurableFiltersSelector: filterSelector },
        ) => {
            const state = getState();
            if (!contentSelector.isContentCategoryTasks(state)) return;
            const taskDefinitionId = contentSelector.getContentItemId(state);
            const filtersSet = filterSelector.getActiveFiltersSet(state);

            if (!filtersSet || !taskDefinitionId) return;

            dispatch({
                type: SAVE_FILTERS_SET,
                filtersSetName,
                taskDefinitionId,
                filtersSet,
            });
        },

    setGeneralAsActiveSet: () => dispatch => {
        return dispatch({ type: SET_GENERAL_AS_ACTIVE });
    },

    setSavedAsActiveSet:
        (filtersSetName: string) =>
        (dispatch, getState, { contentSelector }) => {
            const state = getState();
            const taskDefinitionId = contentSelector.getContentItemId(state);
            if (!contentSelector.isContentCategoryTasks(state) || !taskDefinitionId) return;
            return dispatch({ type: SET_SAVED_AS_ACTIVE, filtersSetName, taskDefinitionId });
        },

    toggleStringOrItemFilterValueIsChecked:
        (filterId: number | string, value: string | number) => dispatch => {
            return dispatch({
                type: TOGGLE_STRING_ITEM_FILTER_VALUE,
                filterId,
                value,
            });
        },

    togglePredefinedFilter: (filter: keyof TaskQuickFilters) => dispatch => {
        return dispatch({
            type: TOGGLE_PREDEFINED_FILTER,
            filter,
        });
    },

    validateAppliedFilters:
        () =>
        async (
            dispatch,
            getState,
            { contentSelector, tasksConfigurableFiltersSelector, taskDefinitionAssigneesCreator },
        ) => {
            const state = getState();
            const taskDefinitionId = contentSelector.getContentItemId(state);
            if (contentSelector.isContentCategoryTasks(state) && taskDefinitionId) {
                const assigneeFilter =
                    tasksConfigurableFiltersSelector.getAppliedAssigneeFilter(state);
                if (assigneeFilter !== null) {
                    await dispatch(taskDefinitionAssigneesCreator.fetchAssigneeValues(true));
                }
                return dispatch({
                    type: VALIDATE_APPLIED_FILTERS,
                    taskDefinitionId,
                    requireDueDate: isRequireDueDate(state),
                });
            }
        },

    getTaskPropertyValues:
        (
            taskPropertyId: number,
            limit: number,
            offset: number,
            resetItems: boolean,
            query?: string,
        ) =>
        async (dispatch, _, { api }) => {
            const taskPropertiesValues = await api.getTaskPropertyValues(
                taskPropertyId,
                limit,
                offset,
                query,
            );
            dispatch({
                taskPropertyId,
                taskPropertiesValues,
                type: GET_TASK_PROPERTIES_VALUES,
                resetItems,
                query,
            });
        },

    getAssigneeFilterMeta:
        (resetState: boolean = false) =>
        async (
            dispatch,
            getState,
            { taskDefinitionAssigneesCreator, tasksConfigurableFiltersSelector: filterSelector },
        ) => {
            await dispatch(taskDefinitionAssigneesCreator.fetchAssigneeValues(resetState));
            const state = getState();
            const filterMeta = filterSelector.getAssigneeFilterMeta(state);

            if (!filterMeta) return;

            return dispatch({
                filterMeta,
                type: GET_ASSIGNEE_FILTER_META,
            });
        },

    toggleAppliedStringFilterValue:
        (filterId: number | string, itemName: string) =>
        (
            dispatch,
            getState,
            { contentCreator: content, contentSelector, tasksConfigurableFiltersSelector },
        ) => {
            const state = getState();
            const taskDefinitionId = contentSelector.getContentItemId(state) as number;

            const defaultFiltersSet = !state.tasksConfigurableFilters.appliedFiltersSets[
                taskDefinitionId
            ]
                ? tasksConfigurableFiltersSelector.getDefaultFiltersSet(state)
                : undefined;

            dispatch({
                type: TOGGLE_APPLIED_STRING_FILTER_VALUE,
                taskDefinitionId,
                filterId,
                itemName,
                defaultFiltersSet,
            });

            return dispatch(content.refresh(false));
        },
};
