import {
    ActivePanelType,
    ALL_TASKS_ID,
    DASHBOARDS_ITEM_ID,
} from '../../constants/navigation.constants';
import { isMobile } from '../../utils/browser.utils';
import {
    CHANGE_ACTIVE_CATEGORY_EXPANDED,
    CHANGE_TASKS_VIEW,
    defaultTasksView,
    MenuAction,
    MenuState,
    SET_ACTIVE_BOTTOM_PANEL,
    SET_ACTIVE_CATEGORY,
    SET_ACTIVE_ITEM,
    SET_MENU_INTIALIZED,
    TasksView,
    TOGGLE_DRAWER_HOVER,
    TOGGLE_DRAWER_PERMANENT,
} from './menu.types';
import { FETCH_FORMS } from '../forms/forms.types';
import { FETCH_TDS } from '../taskDefinitions/taskDefinitions.types';
// eslint-disable-next-line import/no-cycle
import { ThunkActionVoid } from '../index';
import { Category } from '../content/category.types';
import { AppState } from '../AppState.types';
import {
    HYDRATE_STATE_FROM_STORAGE,
    HydrateStateFromStorageAction,
} from '../initialLoader/initialLoader.types';
import { onTasksViewSwitch } from '../../analytics';

export const initialState: MenuState = {
    isInitialized: false,
    isDrawerPermanentOpen: !isMobile,
    isDrawerHoverOpen: false,
    activeBottomPanel: ActivePanelType.Main,
    navigation: {
        activeCategory: null,
        activeItemId: null,
        activeCategoryExpanded: false,
    },
    tasksView: defaultTasksView,
};

const itemIdIsInvalid = (
    categoryToFix: Category,
    activeCategory: Category | null,
    activeItemId: number | null,
    items: { id: number }[],
): boolean => {
    return (
        activeCategory === categoryToFix &&
        activeItemId != null &&
        !items.some(item => item.id === activeItemId)
    );
};

const fixActiveItem = (
    categoryToFix: Category,
    state: MenuState,
    items: { id: number }[],
): MenuState => {
    const { activeItemId, activeCategory } = state.navigation;
    if (itemIdIsInvalid(categoryToFix, activeCategory, activeItemId, items)) {
        return {
            ...state,
            navigation: {
                ...state.navigation,
                activeItemId: categoryToFix === 'tasks' ? ALL_TASKS_ID : items[0]?.id ?? null,
            },
        };
    }
    return state;
};

export default function reducer(
    state = initialState,
    action: MenuAction | HydrateStateFromStorageAction,
): MenuState {
    switch (action.type) {
        case HYDRATE_STATE_FROM_STORAGE:
            const { menu } = action.state;
            if (state.navigation.activeCategory) return state;
            if (!menu) return initialState;
            return {
                ...state,
                navigation: {
                    ...state.navigation,
                    ...menu.navigation,
                },
                tasksView: menu.tasksView || initialState.tasksView,
            };
        case SET_MENU_INTIALIZED:
            return {
                ...state,
                isInitialized: true,
            };

        case TOGGLE_DRAWER_HOVER:
            if (typeof action.isOpen === 'undefined') {
                return {
                    ...state,
                    isDrawerHoverOpen: !state.isDrawerHoverOpen,
                };
            }
            return {
                ...state,
                isDrawerHoverOpen: action.isOpen,
            };
        case TOGGLE_DRAWER_PERMANENT:
            if (typeof action.isOpen === 'undefined') {
                return {
                    ...state,
                    isDrawerPermanentOpen: !state.isDrawerPermanentOpen,
                };
            }
            return {
                ...state,
                isDrawerPermanentOpen: action.isOpen,
            };
        case SET_ACTIVE_BOTTOM_PANEL:
            return {
                ...state,
                activeBottomPanel: action.panel,
            };
        case SET_ACTIVE_CATEGORY:
            return {
                ...state,
                navigation: {
                    ...state.navigation,
                    activeCategory: action.category,
                    activeItemId: action.itemId,
                    activeCategoryExpanded: action.activeCategoryExpanded,
                },
                isInitialized: true,
            };
        case SET_ACTIVE_ITEM:
            return {
                ...state,
                navigation: {
                    ...state.navigation,
                    activeItemId: action.itemId,
                },
            };
        case CHANGE_TASKS_VIEW:
            return {
                ...state,
                tasksView: action.view,
            };
        case FETCH_FORMS:
            return fixActiveItem('forms', state, action.forms);
        case FETCH_TDS:
            return fixActiveItem('tasks', state, action.taskDefinitions);
        case CHANGE_ACTIVE_CATEGORY_EXPANDED:
            return {
                ...state,
                navigation: {
                    ...state.navigation,
                    activeCategoryExpanded: action.activeCategoryExpanded,
                },
            };
        default:
            return state;
    }
}

export const getDefaultSelectedItemForCategory = (
    category: Category | null,
    state: AppState,
): number | null => {
    switch (category) {
        case 'forms':
            return state.forms.forms.length === 0 ? null : state.forms.forms[0].id;
        case 'tasks':
            return ALL_TASKS_ID;
        case 'dashboards':
            return DASHBOARDS_ITEM_ID;
        default:
            return null;
    }
};

export const menuCreator = {
    setMenuInitialized: (): MenuAction => ({
        type: SET_MENU_INTIALIZED,
    }),

    toggleDrawerPermanent: (isOpen?: boolean): MenuAction => ({
        isOpen,
        type: TOGGLE_DRAWER_PERMANENT,
    }),

    toggleDrawerHover: (isOpen?: boolean): MenuAction => ({
        isOpen,
        type: TOGGLE_DRAWER_HOVER,
    }),

    onBottomMenuClick:
        (panel: ActivePanelType): ThunkActionVoid =>
        dispatch => {
            dispatch({
                panel,
                type: SET_ACTIVE_BOTTOM_PANEL,
            });
        },

    setActiveCategory:
        (category: Category): ThunkActionVoid =>
        (dispatch, getState, { contentCreator, tasksSortCreator, menuSelector }) => {
            const state = getState();
            const navigation = menuSelector.getNavigation(state);

            const { activeCategory, activeCategoryExpanded } = navigation;

            if (activeCategory !== category) {
                if (category === 'tasks') {
                    dispatch(tasksSortCreator.resetSort());
                }
                const itemId = getDefaultSelectedItemForCategory(category, state);
                dispatch({
                    category,
                    itemId,
                    activeCategoryExpanded: true,
                    type: SET_ACTIVE_CATEGORY,
                });
                dispatch(contentCreator.changeContentMeta(category, itemId));
            } else {
                dispatch({
                    activeCategoryExpanded: !activeCategoryExpanded,
                    type: CHANGE_ACTIVE_CATEGORY_EXPANDED,
                });
            }
        },

    setActiveItem:
        (itemId: number | null): ThunkActionVoid =>
        (dispatch, getState, { contentCreator, menuSelector }) => {
            const { activeCategory } = menuSelector.getNavigation(getState());
            dispatch({
                itemId,
                type: SET_ACTIVE_ITEM,
            });
            dispatch(contentCreator.changeContentMeta(activeCategory, itemId));
        },

    initializeActiveCategoryAndItem:
        (category: Category, itemId: number | null): ThunkActionVoid =>
        (dispatch, _, { contentCreator }) => {
            dispatch({
                category,
                itemId,
                activeCategoryExpanded: true,
                type: SET_ACTIVE_CATEGORY,
            });
            return dispatch(contentCreator.initializeContentMeta(category, itemId));
        },

    initializeDefault:
        (): ThunkActionVoid =>
        (dispatch, getState, { contentCreator, menuSelector }) => {
            const state = getState();
            const category = menuSelector.getDefaultCategory(state);
            const itemId = getDefaultSelectedItemForCategory(category, state);
            dispatch({
                category,
                itemId,
                activeCategoryExpanded: true,
                type: SET_ACTIVE_CATEGORY,
            });
            return dispatch(contentCreator.initializeContentMeta(category, itemId));
        },

    changeTasksView:
        (view: TasksView): ThunkActionVoid =>
        (
            dispatch,
            getState,
            {
                contentCreator,
                contentSelector,
                contentQuickFiltersSelector,
                taskDefinitionAssigneesCreator,
            },
        ) => {
            const state = getState();
            const currentView = state.menu.tasksView;
            const action = dispatch({
                view,
                type: CHANGE_TASKS_VIEW,
            });
            onTasksViewSwitch(
                view,
                contentQuickFiltersSelector.hasManagementRights(state),
                contentSelector.getContentItemId(state),
            );
            if (currentView === 'calendar' || view === 'calendar') {
                dispatch(contentCreator.refresh(true));
            }
            if (view === 'calendar')
                dispatch(taskDefinitionAssigneesCreator.fetchAssigneeValues(true));
            return action;
        },
};
