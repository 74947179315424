import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { MenuList, Link, Icon } from 'worldapp-ui/shared';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { TaskDefinition } from '../../../../redux/taskDefinitions/taskDefinitions.types';
import { ALL_TASKS_ID } from '../../../../constants/navigation.constants';
import useMenuListStyles from '../MenuList.styles';
import { AppState } from '../../../../redux/AppState.types';
import { getTasksTabName } from '../../../../redux/portalSettings/portalSettingsSelector';
import { getIsDrawerOpened } from '../../../../redux/menu/menuSelector';
import {
    getTotalTasks,
    getTotalTDs,
} from '../../../../redux/taskDefinitions/taskDefinitionsSelector';

import { isIE } from '../../../../utils/browser.utils';
import {
    getCountCalculated,
    getEmptyTaskDefinitions,
    getNonEmptyTaskDefinitions,
} from '../../../../redux/taskDefinitions/taskDefinitionsSelector.reselect';
import NavigationTreeMenuItem from '../NavigationTreeMenuItem';
import { onShowHideEmptyTD } from '../../../../analytics';

export const mapStateToProps = (state: AppState) => ({
    totalTDs: getTotalTDs(state),
    totalTasks: getTotalTasks(state),
    isDrawerOpen: getIsDrawerOpened(state),
    tasksTabName: getTasksTabName(state),
    countCalculated: getCountCalculated(state),
    emptyTaskDefinitions: getEmptyTaskDefinitions(state),
    nonEmptyTaskDefinitions: getNonEmptyTaskDefinitions(state),
});

export type TasksListProps = ReturnType<typeof mapStateToProps> & {
    onItemSelect: (itemId: number) => void;
    selectedItem: number | null;
    iOSView?: boolean;
};

export const TasksList = (props: TasksListProps): JSX.Element | null => {
    const {
        isDrawerOpen,
        totalTDs,
        selectedItem,
        onItemSelect,
        tasksTabName,
        countCalculated,
        emptyTaskDefinitions,
        nonEmptyTaskDefinitions,
        iOSView = false,
    } = props;
    const classes = useMenuListStyles();
    const fullView = isDrawerOpen || iOSView;
    const [isShowEmptyTD, setIsShowEmptyTD] = React.useState(false);
    const { t } = useTranslation();
    const onToggleShowEmpty = () => {
        onShowHideEmptyTD(!isShowEmptyTD);
        setIsShowEmptyTD(!isShowEmptyTD);
    };

    const onItemSelectHandler = (itemId: number) => () => onItemSelect(itemId);

    useEffect(() => {
        if (
            countCalculated &&
            selectedItem !== null &&
            emptyTaskDefinitions.find((td: TaskDefinition) => td.id === selectedItem)
        ) {
            setIsShowEmptyTD(true);
        }
    }, [countCalculated, selectedItem, emptyTaskDefinitions]);

    if (!totalTDs) {
        return null;
    }

    return (
        <MenuList
            className={classNames(classes.menuList, {
                [classes.menuListCompactView]: fullView,
            })}
            variant="selectedMenu"
            autoFocusItem
        >
            <NavigationTreeMenuItem
                isMenuOpen={fullView}
                title={t('NavigationPanel.AllTasks', { tasksTabName })}
                isSelected={selectedItem === ALL_TASKS_ID}
                // count={totalTasks}
                onClick={onItemSelectHandler(ALL_TASKS_ID)}
                data-testid={`tasks-item-${ALL_TASKS_ID}`}
                autoFocus={selectedItem === ALL_TASKS_ID}
            />
            {nonEmptyTaskDefinitions.map((task: TaskDefinition) => (
                <NavigationTreeMenuItem
                    key={`${task.id} ${task.title}`}
                    isMenuOpen={fullView}
                    title={task.title || ''}
                    isSelected={selectedItem === task.id}
                    // count={task.count || 0}
                    onClick={onItemSelectHandler(task.id)}
                    data-testid={`tasks-item-${task.id}`}
                    autoFocus={selectedItem === task.id}
                />
            ))}
            {emptyTaskDefinitions.length > 0 && (
                <li
                    className={classes.showEmptyContainer}
                    role="menuitem"
                    aria-label={
                        isShowEmptyTD
                            ? t('NavigationPanel.HideEmptyTaskDefinitions')
                            : t('NavigationPanel.ShowEmptyTaskDefinitions')
                    }
                >
                    {fullView ? (
                        <Link
                            className={classNames(classes.link, classes.showEmpty, {
                                [classes.linkIE]: isIE && fullView,
                            })}
                            onClick={onToggleShowEmpty}
                            variant="subtitle2"
                            data-testid={'link-show-empty-tasks'}
                        >
                            {isShowEmptyTD
                                ? t('NavigationPanel.HideEmptyTaskDefinitions')
                                : t('NavigationPanel.ShowEmptyTaskDefinitions')}
                        </Link>
                    ) : (
                        <Icon name="dots" size="l" mode="stroke" />
                    )}
                </li>
            )}

            {isShowEmptyTD &&
                emptyTaskDefinitions.map((task: TaskDefinition) => (
                    <NavigationTreeMenuItem
                        key={`${task.id} ${task.title}`}
                        isMenuOpen={fullView}
                        title={task.title || ''}
                        isSelected={selectedItem === task.id}
                        // count={task.count || 0}
                        onClick={onItemSelectHandler(task.id)}
                        data-testid={`tasks-item-${task.id}`}
                        autoFocus={selectedItem === task.id}
                    />
                ))}
        </MenuList>
    );
};

export default connect(mapStateToProps)(TasksList);
