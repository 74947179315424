import { AppState } from '../AppState.types';

export const getOnlineIniOsFeature = (state: AppState): boolean =>
    !!state.features?.online_in_iOS_opx202;

export const getOptimizeFormsTitleLoadFeature = (state: AppState): boolean =>
    !!state.features?.optimize_forms_title_load_opx540;

export default {
    getOptimizeFormsTitleLoadFeature,
};
