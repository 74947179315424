import { getDefaultSelectedItemForCategory } from '../menu/menu';
import { persistentReducers } from '../../storage/storageMiddleware';
import { getStorageKey } from '../../storage/storage.utils';
import { HYDRATE_STATE_FROM_STORAGE } from './initialLoader.types';
import { storage } from '../../storage/localStorage';
import { InitialLoaderCreator } from './initialLoaderCreator.types';
import { ThunkActionVoid } from '../index';
import { getLastPortalId } from '../../utils/storage.utils';
import { LoginStatus } from '../login/login.types';
import { getHasForms } from '../forms/formsSelector';
import { getIsBackUrl, getVotingCreatedItemId, getVotingStartForm } from '../voting/votingSelector';
import { getHasTds } from '../taskDefinitions/taskDefinitionsSelector';
import { getContentCategory, getContentItemId } from '../content/contentSelector';
import { ExportStatus } from '../../constants/export.constants';
import { CONTENT_CATEGORY_FORMS, CONTENT_CATEGORY_TASKS } from '../content/category.types';
import { FORMS_CONTENT_INITIALIZE } from '../content/content.types';

export const initialLoaderCreator: InitialLoaderCreator = {
    onFirstLoad(): ThunkActionVoid {
        return async (dispatch, getState, extra) => {
            try {
                await Promise.all([
                    dispatch(extra.userCreator.fetchUser()),
                    dispatch(extra.loginCreator.fetchLoginOptions()),
                ]);
                const state = getState();
                const isAuthenticated = extra.userSelector.isUserExists(state);

                const portalId = extra.loginSelector.getPortalId(state);
                const loginStatus = extra.loginSelector.getLoginStatus(state);
                // todo // this is a subtle bug - if client is authenticated, there is no way to ask
                // todo // its portalId from server, so we use getLastPortalId from local storage
                const lastPortalId = getLastPortalId();

                if (loginStatus === LoginStatus.ChangePassword && isAuthenticated) {
                    return;
                }
                if (loginStatus === LoginStatus.ResetPassword) {
                    return;
                }
                if (loginStatus === LoginStatus.FailedSSOLogin) {
                    return;
                }
                if (loginStatus === LoginStatus.LogoutPending && isAuthenticated) {
                    return dispatch(extra.loginCreator.logout());
                }

                if (isAuthenticated) {
                    if (lastPortalId && portalId && lastPortalId !== portalId) {
                        return dispatch(
                            extra.loginCreator.changeLoginStatus(
                                LoginStatus.LoginToDifferentPortal,
                            ),
                        );
                    }

                    return dispatch(extra.initialLoaderCreator.initialize());
                }

                if (portalId) {
                    return dispatch(extra.loginCreator.applyPortalId());
                }
                return dispatch(extra.loginCreator.changeLoginStatus(LoginStatus.Portal));
            } catch (err: any) {
                dispatch(extra.loginCreator.changeLoginStatus(LoginStatus.Portal));
                const loginStatus = extra.loginSelector.getLoginStatus(getState());
                return dispatch(extra.loginCreator.addError(err, loginStatus));
            }
        };
    },

    initialize: () => async (dispatch, _, extra) => {
        await dispatch(extra.loginCreator.changePortalId(getLastPortalId()));
        await dispatch(extra.initialLoaderCreator.initializeMain());
        await dispatch(extra.toastsCreator.initializeToasts());

        return dispatch(extra.loginCreator.changeLoginStatus(LoginStatus.LoggedIn));
    },

    initializeMain:
        () =>
        async (
            dispatch,
            getState,
            {
                loginCreator,
                loginSelector,
                formsCreator,
                votingCreator,
                featuresCreator,
                userCreator,
                portalSettingsCreator,
                brandInfoCreator,
                localizationCreator,
                initialLoaderCreator: eInitialLoaderCreator,
            },
        ) => {
            await dispatch(loginCreator.gotoRedirectURLIfNeeded());

            const newResponseParams = loginSelector.getNewResponseParams(getState());
            if (newResponseParams) {
                await dispatch(formsCreator.fetchForms());
                return dispatch(votingCreator.addNewResponse(newResponseParams));
            }
            await dispatch(featuresCreator.checkFeatures());
            await Promise.all([
                dispatch(userCreator.fetchUser()),
                dispatch(portalSettingsCreator.fetchSettings()),
                dispatch(brandInfoCreator.fetchBrandInfo()),
            ]);
            await dispatch(brandInfoCreator.checkVersion());
            await dispatch(eInitialLoaderCreator.loadStorageData());

            await dispatch(localizationCreator.checkLanguageAfterLogin());

            dispatch(eInitialLoaderCreator.fetchMenuAndInit());
        },

    fetchMenuAndInit:
        () =>
        async (
            dispatch,
            getState,
            {
                formsCreator,
                taskDefinitionsCreator,
                contentCreator,
                initialLoaderCreator: eInitialLoaderCreator,
            },
        ) => {
            const formsFetched = dispatch(formsCreator.fetchForms());
            await dispatch(taskDefinitionsCreator.fetchTaskDefinitions());
            formsFetched.then(async () => {
                const state = getState();
                const hasForms = getHasForms(state);
                const hasTds = getHasTds(state);
                if (!hasForms && !hasTds) {
                    dispatch(contentCreator.setInitialState());
                }
            });

            await dispatch(eInitialLoaderCreator.initContent(false));
            formsFetched.then(async () => {
                await dispatch(eInitialLoaderCreator.initContent(true));
                await dispatch(eInitialLoaderCreator.initializeAfterVoting(formsFetched));
            });
        },

    initContent:
        (afterFormsFetched?: boolean) =>
        async (dispatch, getState, { menuCreator, contentSelector }) => {
            const state = getState();
            const category = state.menu.navigation.activeCategory;
            const itemId = state.menu.navigation.activeItemId;
            const isCategoryExist = contentSelector.getContentCategoryExits(category, state);

            if (category === CONTENT_CATEGORY_FORMS && !afterFormsFetched) {
                dispatch(menuCreator.setMenuInitialized());
                return;
            }
            if (afterFormsFetched) dispatch({ type: FORMS_CONTENT_INITIALIZE });
            if (category !== CONTENT_CATEGORY_FORMS && afterFormsFetched) return;

            dispatch(initialLoaderCreator.processTaskExportInProgress());

            if (!category || !isCategoryExist) {
                return dispatch(menuCreator.initializeDefault());
            }

            return dispatch(
                menuCreator.initializeActiveCategoryAndItem(
                    category,
                    itemId ?? getDefaultSelectedItemForCategory(category, getState()),
                ),
            );
        },

    processTaskExportInProgress:
        () =>
        (dispatch, getState, { tasksExportCreator }) => {
            const { tasksExport } = getState();
            const tasksExportStatus = tasksExport && Object.values(tasksExport)[0];
            if (tasksExportStatus?.progress === ExportStatus.IN_PROGRESS) {
                const taskExportDefinitionId = tasksExport && +Object.keys(tasksExport)[0];
                dispatch(
                    tasksExportCreator.getExportTasksStatus(
                        taskExportDefinitionId,
                        tasksExportStatus.location,
                    ),
                );
            }
        },

    initializeAfterVoting:
        (formsFetched?: Promise<void>) =>
        async (
            dispatch,
            getState,
            {
                contentSelector,
                tasksActionsCreator,
                frequentlyUsedCreator,
                responsesActionsCreator,
            },
        ) => {
            const state = getState();
            const isBackUrl = getIsBackUrl(state);

            if (!isBackUrl) {
                return;
            }
            const startForm = getVotingStartForm(state);

            const category = getContentCategory(state);
            const activeItemId = getContentItemId(state);
            const createdItemId = getVotingCreatedItemId(state);
            if (contentSelector.isContentCategoryForms(state) && formsFetched) {
                await formsFetched;
            }

            if (category === CONTENT_CATEGORY_TASKS && activeItemId !== null) {
                dispatch(tasksActionsCreator.handleTasksAfterVoting());
                if (startForm) {
                    dispatch(frequentlyUsedCreator.addItem('taskDefinitions', activeItemId));
                }
            } else if (
                category === CONTENT_CATEGORY_FORMS &&
                activeItemId !== null &&
                createdItemId !== null
            ) {
                dispatch(responsesActionsCreator.handleResponsesAfterVoting());
                if (startForm) {
                    dispatch(frequentlyUsedCreator.addItem('forms', activeItemId));
                }
            }
        },

    loadStorageData: () => {
        return (dispatch, getState) => {
            const { user } = getState();
            const storageData: any = {};
            persistentReducers.forEach(reducerName => {
                const reducerData = storage.get(getStorageKey(reducerName, user.contactId));
                const reducerDataWithoutUser = storage.get(reducerName);
                if (reducerData) {
                    storageData[reducerName] = reducerData;
                } else if (reducerDataWithoutUser) {
                    storageData[reducerName] = reducerDataWithoutUser;
                }
            });

            dispatch({
                type: HYDRATE_STATE_FROM_STORAGE,
                state: storageData,
            });
        };
    },
};
