import React, { CSSProperties, FC, memo, useLayoutEffect, useMemo, useRef } from 'react';
import { Grid, makeStyles } from 'worldapp-ui/shared';
import {
    areEqual,
    FixedSizeList,
    FixedSizeList as List,
    ListOnItemsRenderedProps,
} from 'react-window';
import { TaskCardContentProps } from './CardsGrid.types';
import CardPlaceholder from '../../Placeholders/CardPlaceholder';
import {
    CARDS_HEIGHTS,
    CARDS_MAX_WIDTHS,
    CARDS_PADDING,
    DEFAULT_PADDING,
    GRID_PADDING,
} from '../../ui/cards/cardsSizes';
import { TasksCardsRow } from './TasksCardsRow';
import { MainTaskAction, SecondaryTaskAction } from '../../../redux/content/validateTaskActions';
import { getContentJustifyValue } from '../../../redux/content/content.utils';
import { deviceType, isMobile } from '../../../utils/browser.utils';
import { JustifyValue } from '../../../redux/content/content.types';

type TasksCardsListRowData = {
    hasMore: boolean;
    rowCount: number;
    isMobileDevice: boolean;
    itemsPerRow: number;
    cards: TaskCardContentProps[];
    width: number;
    justifyContentValue: JustifyValue;
    openTaskDetailView: (taskId: number) => void;
    openActionPopup: (action: SecondaryTaskAction, taskId?: number) => void;
    dispatchMainAction: (action: MainTaskAction, taskId: number, taskDefinitionId: number) => void;
    dispatchSelectAction: (taskId: number, checked: boolean) => void;
    dispatchDeselectTasks: () => void;
    dispatchExportAction: (taskId: number) => void;
};

const TasksCardsListRow: FC<{ data: TasksCardsListRowData; index: number; style: CSSProperties }> =
    memo(({ data, index, style }) => {
        const {
            hasMore,
            rowCount,
            isMobileDevice,
            itemsPerRow,
            cards,
            width,
            justifyContentValue,
            openTaskDetailView,
            openActionPopup,
            dispatchMainAction,
            dispatchSelectAction,
            dispatchDeselectTasks,
            dispatchExportAction,
        } = data;
        const restStyle = { ...style };
        delete restStyle.width;

        const showPlaceholder = hasMore && index === rowCount - 1;

        if (showPlaceholder) {
            return (
                <Grid
                    container={true}
                    spacing={isMobileDevice ? 1 : 2}
                    style={{
                        ...restStyle,
                        paddingTop: CARDS_PADDING,
                        left: GRID_PADDING,
                    }}
                >
                    <Grid item={true} key={index}>
                        <CardPlaceholder category="tasks" />
                    </Grid>
                </Grid>
            );
        }

        const fromIndex = index * itemsPerRow;
        const toIndex = Math.min(fromIndex + itemsPerRow, cards.length);
        const cardsInRow = cards.slice(fromIndex, toIndex);

        const cardWidth = Math.min(
            (width - (GRID_PADDING + DEFAULT_PADDING)) / itemsPerRow - CARDS_PADDING,
            CARDS_MAX_WIDTHS.tasks,
        );

        return (
            <Grid
                container={true}
                spacing={isMobileDevice ? 1 : 2}
                justify={justifyContentValue}
                style={{
                    ...restStyle,
                    marginTop: DEFAULT_PADDING,
                    left: justifyContentValue === 'center' ? 0 : GRID_PADDING,
                }}
            >
                <TasksCardsRow
                    cards={cardsInRow}
                    cardWidth={cardWidth}
                    openTaskDetailView={openTaskDetailView}
                    dispatchMainAction={dispatchMainAction}
                    dispatchSelectAction={dispatchSelectAction}
                    dispatchDeselectTasks={dispatchDeselectTasks}
                    dispatchExportAction={dispatchExportAction}
                    openActionPopup={openActionPopup}
                />
            </Grid>
        );
    }, areEqual);

const tasksGridStyles = makeStyles(() => ({
    cardsContainer: {
        overflowX: 'hidden!important' as 'hidden',
        paddingBottom: 0,
        '&[data-ismobile="true"]': {
            paddingBottom: 94,
        },
    },
}));

interface TaskCardsGridListProp {
    cards: TaskCardContentProps[];
    openTaskDetailView: (taskId: number) => void;
    openActionPopup: (action: SecondaryTaskAction, taskId?: number) => void;
    dispatchMainAction: (action: MainTaskAction, taskId: number, taskDefinitionId: number) => void;
    dispatchSelectAction: (taskId: number, checked: boolean) => void;
    dispatchDeselectTasks: () => void;
    dispatchExportAction: (taskId: number) => void;
    height: number;
    width: number;
    rowCount: number;
    itemsPerRow: number;
    listRef: React.Ref<any>;
    onItemsRendered: (props: ListOnItemsRenderedProps) => any;
    hasMore: boolean;
    activeCardPosition: number;
}

export const TaskCardsGridList = memo((props: TaskCardsGridListProp): JSX.Element => {
    const {
        cards,
        openTaskDetailView,
        openActionPopup,
        dispatchMainAction,
        height,
        width,
        rowCount,
        itemsPerRow,
        listRef,
        onItemsRendered,
        hasMore,
        activeCardPosition,
        dispatchSelectAction,
        dispatchDeselectTasks,
        dispatchExportAction,
    } = props;

    const classes = tasksGridStyles();
    const virtualLoaderRef = useRef<FixedSizeList | null>(null);
    const justifyContentValue = getContentJustifyValue(width, 'tasks');

    useLayoutEffect(() => {
        if (virtualLoaderRef.current) {
            const activeRow = Math.ceil(activeCardPosition / itemsPerRow);
            virtualLoaderRef.current.scrollToItem(activeRow);
        }
    }, [activeCardPosition, itemsPerRow]);

    const isMobileDevice = deviceType === 'mobile';

    const itemdData = useMemo(
        () => ({
            hasMore,
            rowCount,
            isMobileDevice,
            itemsPerRow,
            cards,
            width,
            justifyContentValue,
            openTaskDetailView,
            openActionPopup,
            dispatchMainAction,
            dispatchSelectAction,
            dispatchDeselectTasks,
            dispatchExportAction,
        }),
        [
            cards,
            dispatchDeselectTasks,
            dispatchExportAction,
            dispatchMainAction,
            dispatchSelectAction,
            hasMore,
            isMobileDevice,
            itemsPerRow,
            justifyContentValue,
            openActionPopup,
            openTaskDetailView,
            rowCount,
            width,
        ],
    );

    return (
        <List
            height={height}
            itemCount={rowCount}
            itemSize={
                isMobileDevice ? CARDS_HEIGHTS.tasks + 12 : CARDS_HEIGHTS.tasks + CARDS_PADDING
            }
            itemData={itemdData}
            width={width}
            className={classes.cardsContainer}
            ref={list => {
                if (typeof listRef === 'function') {
                    listRef(list);
                }
                virtualLoaderRef.current = list;
            }}
            onItemsRendered={onItemsRendered}
            data-ismobile={isMobile}
        >
            {TasksCardsListRow}
        </List>
    );
});
